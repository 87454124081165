/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-04 13:37:17
 * @最后修改人: zhangzhipeng
 * @最后修改时间: 2022-04-25 13:14:56
 * @Description: 文件说明
 */
/* eslint-disable no-param-reassign */

/**
 * request 网络请求工具
 * 更详细的 api 文档: fechs://github.com/umijs/umi-request
 */
import { Canceler, extend } from 'umi-request';
import request from 'umi-request';
import { Modal, notification } from 'antd';
import { message } from 'antd';
import global from '@/utils/global';
import { history } from 'umi';
import { iframeDownload } from '@/utils/global/ecg';
import qs from 'qs';
import { BussinessNo } from './BussinessNo';
import xss from 'xss';
import { Mobx, Swr } from '@/store';
import { toJS } from 'mobx';
import { deepCopy, ENV, isNotNULL } from './data';
import { get_query } from '@/utils/global/tools';
// import axios from 'axios';

// const { Mobx } = STORE();
// const { Swr } = STORE();
export type CONFIG_HTTP = {
  /**
   *弹窗：传值是要弹出的key
   *
   * @type {string}
   */
  openwindow?: string;
  /**
   *下载：传值是要下载的key
   *
   * @type {string}
   */
  download?: string;
  /**
   *请求头
   *
   * @type {{
   *     oper:
   *     ecgid:
   *     repid:
   *    }}
   */
  headers?: Record<string, any>;
  responseType?: 'json' | 'text' | 'blob' | 'arrayBuffer' | 'formData' | undefined;
  /**
   * 是否显示屏蔽罩
   */
  loading?: boolean;
  /**
   * 弹窗：传值是key
   */
  alert?: string;
  /**
   * 错误提醒弹窗：传值是key
   */
  err_alert?: string;
  /**
   * 弹出想显示的字符串
   */
  msg?: string;
  /**
   * 确认提醒
   */
  confirm?: any;
};
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
const configHttp = {
  cache: true,
  Swr: true,
  cacheTime: 60 * 1000,
  swrTime: 500,
};
const res_list_name = ['rows', 'data']; // 将后台数据结构统一

// 显示log的配置req
function noLogReq(url: string) {
  if (
    url.indexOf('/ums/menuData.action') === -1 &&
    url.indexOf('/logoutJson.action') === -1 &&
    url.indexOf('/basedata/userOpt.action') === -1 &&
    url.indexOf('/ums/getUnReadMessage.action') === -1 &&
    url.indexOf('/keeplive.action') === -1
  ) {
    // @ts-ignore
    return REACT_APP_ENV.split('@')[1] === 'zzp11';
  }
  return false;
}

// 显示log的配置res
function noLog(url: string) {
  // return false;
  if (
    url.indexOf('/ums/menuData.action') === -1 &&
    url.indexOf('/logoutJson.action') === -1 &&
    url.indexOf('/basedata/userOpt.action') === -1 &&
    url.indexOf('/ums/getUnReadMessage.action') === -1 &&
    url.indexOf('/keeplive.action') === -1
  ) {
    // @ts-ignore
    return REACT_APP_ENV.split('@')[1] === 'zzp111';
  }
  return false;
}
// 显示alert的配置
function noAlert(url: string) {
  // return false;
  return (
    url.indexOf('/logoutJson.action') === -1 &&
    url.indexOf('/basedata/userOpt.action') === -1 &&
    url.indexOf('/ums/getUnReadMessage.action') === -1 &&
    url.indexOf('/keeplive.action') === -1
  );
}
/**
 * 异常处理程序
 */
const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;

  // console.error(`异常${JSON.stringify(error?.response)}`);
  // console.error(error);
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    console.error(`服务器错误 ${status}: ${url} ---${errorText}`);
    // 开发环境
    if (Mobx.notificationShow === false) {
      notification.error({
        message: `服务器错误 ${status}`,
        description: errorText,
        onClose: () => {
          Mobx.notificationShow = false;
        },
      });
      Mobx.notificationShow = true;
    }
    // Mobx.login = 'no';
  } else {
    // 开发环境
    // if (process.env.NODE_ENV === 'development') {
    if (request.isCancel(error) || !error?.response) {
      console.log('主动取消请求', error);
    } else {
      console.error('您的网络发生异常，无法连接服务器');
      if (Mobx.notificationShow === false) {
        notification.error({
          description: '您的网络发生异常，无法连接服务器',
          message: '网络异常',
          onClose: () => {
            Mobx.notificationShow = false;
          },
        });
        Mobx.notificationShow = true;
      }
    }
    // }
  }
  if (response && response.url.indexOf('/zxws') === -1) {
    console.error('网络异常');
    // Mobx.login = 'no';
  }

  return response;
};
/**
 * 配置request请求时的默认参数
 */
export const fetch = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
  // mode:"cors",
  prefix: '',
  // timeout: 1000,
  headers: {
    // 'Content-Type': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    // Accept: 'application/json, text/plain, */*',
  },
  responseType: 'json',
  // useCache:true
});

/**
 * 中间件
 */
fetch.use(async (ctx, next) => {
  const { req } = ctx;
  const { url, options } = req;

  if (noLogReq(url)) {
    console.log(
      `┌───────────────────────────────────────────────────────────────────────────────────────────────────`,
    );
    console.log(`│■■■■■■■■■请求数据之前处理【中间件】■■■■■■■■■`);
    console.log(`│url=${url}`);
    console.log(options.params);
    console.log(JSON.parse(JSON.stringify(qs.parse(options.data))));
    console.log(
      `┕──────────────────────────────────────────────────────────────────────────────────────────────────`,
    );
  }

  // 判断是否需要添加前缀，如果是统一添加可通过 prefix、suffix 参数配置
  // if (url.indexOf('/api') !== 0) {
  //   ctx.req.url = `/api/v1${url}`;
  // }
  // console.log(JSON.parse(JSON.stringify(options.data)));
  if (url.indexOf('/hs/') !== -1 || url.indexOf('/api/restful/') !== -1) {
    options.headers!['Content-Type'] = 'application/json';
    // options.data.map((value: any) => {
    //     return xss(value);
    // });
  } else {
    // XSS攻击
    xss(options.data);
    // console.log(JSON.parse(JSON.stringify(options.data)));
  }

  await next();
  const { res } = ctx;
  const { success = false } = res; // 假设返回结果为 : { success: false, errorCode: 'B001' }
  if (!success) {
    // 对异常情况做对应处理
  }
  // @ts-ignore
  if (noLog(url)) {
    console.log(
      `┌───────────────────────────────────────────────────────────────────────────────────────────────────`,
    );
    console.log(`│□□□□□□返回数据，后处理【中间件】□□□□□□□`);
    console.log(`│url=${url}`);
    console.log(`│请求的`);
    console.log(ctx.req.options.params);
    console.log(JSON.parse(JSON.stringify(qs.parse(ctx.req.options.data))));
    console.log(`│返回的`);
    console.log(JSON.parse(JSON.stringify(res)));
    console.log(
      `┕──────────────────────────────────────────────────────────────────────────────────────────────────`,
    );
  }
  // 将后台数据结构统一
  res_list_name.map(function (e, i, array) {
    const res_list_name_old = ctx.res[e];
    if (res_list_name_old) {
      delete ctx.res[e];
      ctx.res.data = res_list_name_old;
    }
    return parseInt(e, 10);
  });
  // 登录接口数据替换
  // if (url.indexOf('/loginJson.action') === 0) {
  //   ctx.res.status = 'ok';
  //   ctx.res.currentAuthority = 'admin';
  //   ctx.res.type = 'account';
  // }
});
export const source = fetch.CancelToken.source();
const CancelToken = fetch.CancelToken;
const cancel_type: 'CancelToken' | 'AbortController' = 'CancelToken';
function setCancelToken(c: any, url: string) {
  // const timestamp = new Date().getTime();
  // console.log(url,c)
  Swr.c_list = {
    ...Swr.c_list,
    ...{ [`${url}`]: c },
  };
}
function middleware(
  resolve: any,
  reject: any,
  data: any,
  url: any,
  config: CONFIG_HTTP,
  pause: any,
): any {
  const re = /select|update|truncate|join|union|exec|insert|drop|count/i;
  // console.log(data)
  if (!global.DATA.isNotNULL(data)) {
    pause.next(true);
    return;
  }
  // sql注入拦截
  const keys: any = Object.keys(data);
  if (url.indexOf('/hs/') !== -1 || url.indexOf('/api/restful/') !== -1) {
    for (let i = 0; i < keys.length; i += 1) {
      if (re.test(data[keys[i]])) {
        const data_i = data[keys[i]] + '';
        if (data_i.indexOf('data:') === -1 && re.test(data[keys[i]])) {
          message.error(`您输入了非法字符！${data[keys[i]]}`);
          pause.next(false);
          return;
        }
      }
    }
  } else {
    for (let i = 0; i < keys.length; i += 1) {
      if (re.test(data[keys[i]])) {
        const data_i = data[keys[i]] + '';
        if (data_i.indexOf('data:') === -1 && re.test(data[keys[i]])) {
          message.error(`您输入了非法字符！${data[keys[i]]}`);
          pause.next(false);
          return;
        }
      }
    }
  }
  // ==============================================Swr
  //   const timestamp = new Date().getTime();
  //   const binaryData = data ? global.DATA.strToBinary(JSON.stringify(data)) : '';
  //   Swr.no_end_list = [
  //     ...Swr.no_end_list,
  //     {
  //       url,
  //       config,
  //       timestamp,
  //       binaryData
  //     },
  //   ];
  // console.log(Swr.no_end_list)
  // ================================================Swr end
  // 验证config
  if (config && JSON.stringify(config) !== '{}') {
    if (
      config.download ||
      config.headers ||
      config.loading ||
      config.openwindow ||
      config.alert ||
      config.alert === '' ||
      config.msg ||
      config.confirm
    ) {
      if (config?.confirm) {
        Modal.confirm({
          title: config?.confirm,
          okType: 'danger',
          onOk: () => {
            pause.next(true);
            return;
          },
          onCancel: () => {
            if (Mobx.loading) {
              Mobx.loading = false;
            }
            config.loading = undefined;
            pause.next(false);
            return;
          },
        });
      } else {
        pause.next(true);
        return;
      }
    } else {
      message.error('config格式错误');
      config.loading = undefined;
      pause.next(false);
      return;
    }
  } else {
    pause.next(true);
    return;
  }
}
/**
 *
 *
 * @param {*} url
 * @param {*} data
 * @return {*}
 */
function changeUrlQuery(url: any, data: any, config?: CONFIG_HTTP): any {
  if (config?.loading === true) {
    Mobx.loading = true;
  }

  if (url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
    return `${url}`;
  }

  let res = url;

  const vars = res.split('/');
  for (let i = 0; i < vars.length; i += 1) {
    if (
      global.DATA.isNotNULL(vars[i]) &&
      vars[i].indexOf('{') !== -1 &&
      vars[i].indexOf('}') !== -1
    ) {
      const str = vars[i].match(/{(\S*)}/)![1];
      res = res.replace(vars[i], data[str]);

      if (data[str] === undefined) {
        notification.error({
          description: `${str}这个值没传`,
          message: '传值错误',
        });
      }
    }
  }
  // 开发环境
  if (process.env.NODE_ENV === 'development') {
    // 适应新的web服务器
    if (window.location.hash !== '') {
      if (window.location.pathname.indexOf('/api/') !== -1) {
        return `${window.location.pathname.replace('/api/', '/')}${res}`;
      }
      return global.api.url + res;
    }

    return global.api.url + res;
  }
  // 生产环境
  if (window.location.hash !== '') {
    // 适应新的web服务器
    if (window.location.pathname.indexOf('/api/') !== -1) {
      // return `/zxapi${res}`;
      return REACT_APP_ENV && REACT_APP_ENV.split('@')[0] === 'zxapi'
        ? `/zxapi${res}`
        : `${window.location.pathname.replace('/api/', '/')}${res}`;
    }
    // return `/zxapi${res}`;
    return REACT_APP_ENV && REACT_APP_ENV.split('@')[0] === 'zxapi'
      ? `/zxapi${res}`
      : global.api.url + res;
  }
  return global.api.url + res;
}

function then(
  resolve: any,
  reject: any,
  res: any,
  URL: string,
  config: CONFIG_HTTP,
  loading?: boolean,
) {
  let resultCode = 1;
  if (loading) {
    message.loading({ content: '等待提交数据完成...', key: 'updatable', duration: 1 }).then();
  }
  // 取消请求的函数清除start
  // console.log(URL);
  const c_list = deepCopy(Swr.c_list);
  delete c_list[URL];
  Swr.c_list = c_list;
  // 取消请求的函数清除end

  // 如果后面还有待loading的接口请求没回来,则不去除loading start

  // let no_close_loading = false;
  // const find_index = [];
  // for (let index = 0; index < Swr.no_end_list.length; index++) {
  //   if (Swr.no_end_list[index]?.config?.loading === true) {
  //     no_close_loading = true;
  //   }
  //   if (Swr.no_end_list[index]?.url === URL) {
  //     find_index.push(index);
  //   }
  // }
  // if (find_index.length !== 0) {
  //   const swr_clone = deepCopy(Swr.no_end_list);
  //   for (let index = 0; index < find_index.length; index++) {
  //     swr_clone.splice(find_index[index], 1);
  //   }
  //   Swr.no_end_list = swr_clone;
  // }
  // if (no_close_loading) {
  // } else {
  //   if (Mobx.loading === true) {
  //     console.log(new Date().toString(), 'loading结束1');
  //     Mobx.loading = false;
  //   }
  // }
  // if (Swr.no_end_list.length === 0) {
  //   if (Mobx.loading === true) {
  //     console.log(new Date().toString(), 'loading结束2');
  //     Mobx.loading = false;
  //   }
  // }
  Mobx.loading = false;
  // 如果后面还有待loading的接口请求没回来,则不去除loading end
  if (config && config.responseType && config.responseType === 'blob') {
    resolve(res);
    return;
  }

  if (res && res.status && res.status !== 200) {
    reject('http-catch:数据异常,非200' + URL);
    // Mobx.login = 'no';
  }
  /*if (URL.indexOf(`OvO=${document.title}`) === -1) {
     reject('切换页面:被动取消请求' + URL);
   }*/

  if (noAlert(URL)) {
    if (global.DATA.isNotNULL(res) && res !== '') {
      // 因为后台即返回了成功消息，也返回了失败消息，只有失败消息唯一时才弹失败消息
      if (global.DATA.isNotNULL(res.actionErrors)) {
        if (res.actionErrors.length !== 0) {
          if (global.DATA.isNotNULL(res.actionMessages)) {
            if (res.actionMessages.length !== 0) {
              if (res.actionMessages[0] !== res.actionErrors[0]) {
                if (res.actionErrors[0] === '登录失效，请重新登录') {
                  resultCode = 0;
                  reject('http-catch2:登录失效，请重新登录');
                  console.error('http-catch2:登录失效，请重新登录');
                  if (window.location.hash.indexOf('/login') === -1) {
                    Mobx.login = 'no';
                  }
                } else {
                  if (global.DATA.isNotNULL(config?.alert) && config?.alert !== '') {
                    message.error(res.actionErrors[0]);
                  }
                }
              }
            }
          } else {
            if (res.actionErrors[0] === '登录失效，请重新登录') {
              resultCode = 0;
              reject('http-catch4:登录失效，请重新登录');
              console.error('http-catch4:登录失效，请重新登录');
              if (window.location.hash.indexOf('/login') === -1) {
                Mobx.login = 'no';
              }
            } else {
              resultCode = 0;
              if (!config?.alert?.length) {
                if (config?.alert !== '') {
                  message.error(res.actionErrors[0]);
                }
              } else {
                message.error(res.actionErrors[0]);
              }
            }
          }
        }
      }
      // 新接口返回错误的判断
      if (global.DATA.isNotNULL(res.resultCode)) {
        if (res.resultCode === 'DENIED' && window.location.hash.indexOf('/login') === -1) {
          console.error('DENIED:登录失效，请重新登录');

          Mobx.login = 'no';
          reject('登录失效，请重新登录11');
        }
        if (res.resultCode === 'NOLOGIN' && window.location.hash.indexOf('/login') === -1) {
          console.error('NOLOGIN:登录失效，请重新登录');
          Mobx.login = 'no';
          reject('NOLOGIN:登录失效，请重新登录');
        }
        if (res.resultCode === 'FAILED' || res.resultCode === 'ERROR') {
          resultCode = 0;
          if (config?.err_alert !== '') {
            if (res.msg) {
              message.error(res.msg);
              reject(res.msg);
            } else {
              message.error('错误操作！');
              reject('错误操作！');
            }
          }
        }
      }
      if (JSON.stringify(res).indexOf('<!DOCTYPE html>') !== -1) {
        resultCode = 0;
        reject('返回了<!DOCTYPE html>！');
        if (JSON.stringify(res).indexOf('用户登录') !== -1) {
          console.error('返回数据错误:登录失效，请重新登录');
          Mobx.login = 'no';
        } else {
          /*notification.error({
            description: '服务器错误!',
            message: '返回了<!DOCTYPE html>！',
          });*/
          Mobx.login = 'no';
          console.error('返回数据错误返回了<!DOCTYPE html>！:登录失效，请重新登录');
        }
      }
      if (global.DATA.isNotNULL(res.actionMessages)) {
        if (res.actionMessages.length !== 0) {
          if (global.DATA.isNotNULL(config?.alert)) {
          } else {
            if (config?.alert !== '') {
              message.success(res.actionMessages[0]);
            }
          }
        }
      }
      resolve(res);
    } else {
      reject('http-catch:返回数据错误,数据为空值');
    }
  } else {
    resolve(res);
  }
  // 弹窗
  if (global.DATA.isNotNULL(config)) {
    if (global.DATA.isNotNULL(config?.openwindow)) {
      window.open(res.data[config!.openwindow!]);
    }

    // 弹窗alert

    if (global.DATA.isNotNULL(config?.alert) && resultCode !== 0 && config?.alert !== '') {
      if (global.DATA.isNotNULL(res[config!.alert!])) {
        message.success(res[config!.alert!]).then();
      } else {
        if (!config?.msg) {
          message.success('操作成功!').then();
        }
      }
    }

    // 弹窗msg

    if (global.DATA.isNotNULL(config?.msg) && resultCode !== 0) {
      message.success(config!.msg).then();
    }

    // 下载

    if (global.DATA.isNotNULL(config?.download))
      if (global.DATA.isNotNULL(res.data[config!.download!])) {
        let url;
        console.log(toJS(res.data[config!.download!]));
        if (res.data[config!.download!].indexOf('//') === -1) {
          const origin = window.location.origin;
          const base = ENV();
          url = `${origin}/${base}/${res.data[config!.download!]}`;
        } else {
          url = res.data[config!.download!];
        }
        console.log('源文件URL', url);
        iframeDownload(url);
      } else {
        notification.error({
          description: '文件不存在',
          message: '返回数据错误',
        });
      }
  }
  resolve(res);
}
function catchs(resolve: any, reject: any, err: any, loading?: boolean) {
  Mobx.loading = false;
  if (loading) {
    message.loading({ content: '等待提交数据完成...', key: 'updatable', duration: 1 }).then();
  }
  if (fetch.isCancel(err)) {
    console.error('主动取消请求', err.message);
  } else {
    console.error(err);
    if (!err.response) {
      console.error('请求错误,无法连接服务器');
      reject(err);
      // Message是element库的组件，可以去掉
      message.error('请求错误,无法连接服务器').then();
    } else {
      message.error('请求错误,无法连接服务器2').then();
      console.error('请求错误,无法连接服务器2');
      reject(err);
    }
  }
}
/**
 *权限添加
 *
 * @param {*} data
 */
function bussinessNo(data: any) {
  const res = data;
  if (res) {
    if (res.bussinessNo === '') {
      delete res.bussinessNo;
      return res;
    }
    res.bussinessNo = res.bussinessNo ? res.bussinessNo : BussinessNo();
  }

  return res;
}

function* NEXT(fetch_put: any) {
  // @ts-ignore
  const a = yield 1;
  if (a === true) {
    fetch_put(true);
    yield true;
  }
  if (a === false) {
    fetch_put(false);
    return false;
  }

  yield 2;
  return 3;
}
/**
 * get请求，其他类型请求复制粘贴，修改method
 * @param URL
 * @param param
 * @param config
 * @returns
 */
async function get(URL: any, param?: any, config?: CONFIG_HTTP) {
  return new Promise((resolve, reject) => {
    // 适应新的路由
    URL = changeUrlQuery(URL, param, config);
    // 权限添加
    param = bussinessNo(param);
    if (URL.indexOf('/cust/ecg/loadRepInfo.action') > -1) {
      const queryobj = get_query();
      const preFlag: any = queryobj?.preFlag ? Number(queryobj?.preFlag) : 0;
      if (preFlag !== undefined && preFlag === 1) {
        param = { ...param, operSrc: 9 };
      }
    }

    const controller = new AbortController();
    const signal = cancel_type === 'AbortController' ? controller.signal : undefined;
    if (signal) {
      setCancelToken(controller, URL);
    }

    const fetch_put = (cheng: boolean) => {
      if (cheng) {
        fetch(URL, {
          method: 'get',
          params: param === undefined ? {} : param,
          cancelToken: signal
            ? undefined
            : new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                // cancel = c;
                setCancelToken(c, URL);
              }),
          signal,
          headers: config?.headers,
          responseType: config?.responseType,
          getResponse: config?.responseType === 'blob',
        })
          .then((res) => {
            then(resolve, reject, res, URL, config!);
          })
          .catch((err) => {
            catchs(resolve, reject, err);
          });
      } else {
        reject('被拦截');
      }
    };
    const pause = NEXT(fetch_put);
    pause.next();
    middleware(resolve, reject, param, URL, config!, pause);
  });
}
/**
 * post
 * @param URL
 * @param data
 * @param config
 * @returns
 */
async function post(URL: any, data?: any, config?: CONFIG_HTTP) {
  return new Promise((resolve, reject) => {
    // 适应新的路由
    URL = changeUrlQuery(URL, data, config);
    // 权限添加
    data = bussinessNo(data);
    const queryobj = get_query();
    const preFlag: any = queryobj?.preFlag ? Number(queryobj?.preFlag) : 0;
    if (preFlag !== undefined && preFlag === 1) {
      data = { ...data, oflag: 9 };
    }
    const controller = new AbortController();
    const signal = cancel_type === 'AbortController' ? controller.signal : undefined;
    if (signal) {
      setCancelToken(controller, URL);
    }
    const fetch_put = (cheng: boolean) => {
      if (cheng) {
        fetch(URL, {
          method: 'post',
          // params: data,
          cancelToken: signal
            ? undefined
            : new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                // cancel = c;
                setCancelToken(c, URL);
              }),
          signal,
          // data,
          data:
            (URL.indexOf('/hs/') !== -1 ||  URL.indexOf('/api/restful/') !== -1)
              ? data
              : URL.indexOf('http://') !== -1
              ? JSON.stringify(data)
              : qs.stringify(data),
          headers: config?.headers,
        })
          // fetch.post(URL, {
          //   data,
          // })
          .then((res: any) => {
            then(resolve, reject, res, URL, config!);
          })
          .catch((err) => {
            catchs(resolve, reject, err);
          });
      } else {
        reject(console.log('被拦截'));
      }
    };
    const pause = NEXT(fetch_put);
    pause.next();
    middleware(resolve, reject, data, URL, config!, pause);
  });
}
/**
 * put
 * @param URL
 * @param data
 * @param config
 * @returns
 */
async function put(URL: any, data?: any, config?: CONFIG_HTTP) {
  return new Promise((resolve, reject) => {
    // 适应新的路由
    URL = changeUrlQuery(URL, data, config);
    // 权限添加
    data = bussinessNo(data);
    const queryobj = get_query();
    const preFlag: any = queryobj?.preFlag ? Number(queryobj?.preFlag) : 0;
    if (preFlag !== undefined && preFlag === 1) {
      data = { ...data, oflag: 9 };
    }
    const controller = new AbortController();
    const signal = cancel_type === 'AbortController' ? controller.signal : undefined;
    if (signal) {
      setCancelToken(controller, URL);
    }
    const fetch_put = (cheng: boolean) => {
      if (cheng) {
        // message.loading({ content: '等待提交数据完成...', key: 'updatable', duration: 0 });
        fetch(URL, {
          method: 'put',
          // params: data,
          cancelToken: signal
            ? undefined
            : new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                // cancel = c;
                setCancelToken(c, URL);
              }),
          signal,
          data,
          headers: config?.headers,
        })
          // fetch.post(URL, {
          //   data,
          // })
          .then((res: any) => {
            then(resolve, reject, res, URL, config!);
          })
          .catch((err) => {
            catchs(resolve, reject, err, true);
          });
      } else {
        // message.loading({ content: '等待提交数据完成...', key: 'updatable', duration: 1 });

        reject(console.log('被拦截'));
      }
    };
    const pause = NEXT(fetch_put);
    pause.next();
    middleware(resolve, reject, data, URL, config!, pause);
  });
}
/**
 * 删除
 * @param URL
 * @param data
 * @param config
 * @returns
 */
async function _delete(URL: any, data?: any, config?: CONFIG_HTTP) {
  return new Promise((resolve, reject) => {
    // 适应新的路由
    URL = changeUrlQuery(URL, data, config);
    // 权限添加
    data = bussinessNo(data);
    const queryobj = get_query();
    const preFlag: any = queryobj?.preFlag ? Number(queryobj?.preFlag) : 0;
    if (preFlag !== undefined && preFlag === 1) {
      data = { ...data, oflag: 9 };
    }
    const controller = new AbortController();
    const signal = cancel_type === 'AbortController' ? controller.signal : undefined;
    if (signal) {
      setCancelToken(controller, URL);
    }
    const fetch_put = (cheng: boolean) => {
      if (cheng) {
        // message.loading({ content: '等待提交数据完成...', key: 'updatable', duration: 0 });
        fetch(URL, {
          method: 'delete',
          // params: data,
          cancelToken: signal
            ? undefined
            : new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                // cancel = c;
                setCancelToken(c, URL);
              }),
          signal,
          data,
          headers: config?.headers,
        })
          .then((res: any) => {
            then(resolve, reject, res, URL, config!);
          })
          .catch((err) => {
            catchs(resolve, reject, err, true);
          });
      } else {
        // message.loading({ content: '等待提交数据完成...', key: 'updatable', duration: 1 });

        reject(console.log('被拦截'));
      }
    };
    const pause = NEXT(fetch_put);
    pause.next();
    middleware(resolve, reject, data, URL, config!, pause);
  });
}
/**
 * 下载文件
 * @param URL
 * @param config
 * @returns
 */
async function downloadFile(URL: any, config?: CONFIG_HTTP) {
  console.log('source.token === ', source.token);
  const controller = new AbortController();
  // 适应新的路由
  URL = changeUrlQuery(URL, {}, config);
  const signal = cancel_type === 'AbortController' ? controller.signal : undefined;
  if (signal) {
    setCancelToken(controller, URL);
  }
  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: 'get',
      cancelToken: signal
        ? undefined
        : new CancelToken((c) => {
            // An executor function receives a cancel function as a parameter
            // cancel = c;
            setCancelToken(c, URL);
          }),
      signal,
      headers: config?.headers,
    })
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {});
  });
}
/**
 * 批量get请求，其他类型请求复制粘贴，修改method
 * @param URL
 * @param param
 * @param config
 * @returns
 */
async function gets(URL: any, param?: any[], config?: CONFIG_HTTP) {
  return new Promise((resolve, reject) => {
    if (isNotNULL(param) && param?.length) {
      const promise_list = [];
      for (let i = 0; i < param!.length; i++) {
        promise_list.push(get(URL, param![i], config));
      }
      Promise.all(promise_list).then((result) => {
        resolve(result);
      });
    } else {
      notification.error({
        description: 'param格式必须为数组',
        message: '传参错误',
      });
      return reject('param格式必须为数组');
    }
  });
}
export function cancel_url(rex: RegExp) {
  const keys2 = Object.keys(Swr.c_list);
  const rex2 = new RegExp(rex);
  // console.log('keys2:'+keys2)
  // console.log('rex2:'+rex2)
  if (keys2 && keys2.length) {
    for (let i = 0; i < keys2.length; i++) {
      const result2 = rex2.test(keys2[i]);
      if (result2) {
        const url2 = keys2[i];
        try {
          if (Swr.c_list[url2]) {
            if (Swr.c_list[url2].abort) {
              Swr.c_list[url2].abort();
            } else {
              Swr.c_list[url2]('取消请求:' + url2);
            }
            // Swr.c_list[url2]('取消请求:' + url2);
            console.log('取消请求:' + url2);
            // console.trace()
          }
        } catch (e) {
          console.error('error', e);
        }
      }
    }
  }
}
export function cancel_url_string(url: string) {
  try {
    if (Swr.c_list[url]) {
      if (Swr.c_list[url].abort) {
        Swr.c_list[url].abort();
      } else {
        Swr.c_list[url]('取消请求:' + url);
      }
      console.log('取消请求:' + url);
    }
  } catch (e) {
    console.error('error', e);
  }
}
export function cancel_all(rex?: any) {
  try {
    const keys = Object.keys(Swr.c_list);
    for (let index = 0; index < keys.length; index++) {
      if (Swr.c_list[keys[index]].abort) {
        Swr.c_list[keys[index]].abort();
      } else if(rex && rex.test(keys[index])){
        break;
      }else {
        Swr.c_list[keys[index]]('取消请求:' + keys[index]);
      }

      //
      console.log('取消请求:' + keys[index]);
    }
  } catch (e) {
    console.error('error', e);
  }
}
export default {
  cancel_all,
  cancel_url,
  fetch,
  source,
  // get请求，其他类型请求复制粘贴，修改method
  get,
  // post请求，其他类型请求复制粘贴，修改method
  post,
  // put请求，其他类型请求复制粘贴，修改method
  put,
  // delete请求，其他类型请求复制粘贴，修改method
  delete: _delete,
  downloadFile,
  /**
   * 批量get请求，其他类型请求复制粘贴，修改method
   * @param URL
   * @param param
   * @param config
   * @returns
   */
  gets,
};
